<template> 
    <div>
        <competition-header 
          v-model="currentCompetition" :showBackButton="false">
        </competition-header>   
        <h3>Classement final {{ currentCompetition.isCompleted ? '' : '(temporaire)'}}</h3>
        <final-results-criterias-and-options v-slot="criteriasSlotProps" :hasOpens="currentCompetition.isOpen" displaySortOptionsAsSelect>
            <levels v-model="LEVELS" v-slot="levelSlotProps" hideSingleItemTitle>
                <categories v-model="levelSlotProps.level.R_CATEGORIES" v-slot="categorySlotProps" iconSize="xsm">
                    <h5>{{ categorySlotProps.category.name }}</h5>
                    <final-results-table :Results="categorySlotProps.category.R_COMPETITORS" :excludeOpens="criteriasSlotProps.excludeOpens" :sortType="criteriasSlotProps.sortType">
                    </final-results-table>
                </categories>
            </levels>
        </final-results-criterias-and-options>
    </div>
</template>

<script>
import FinalResultsTable from './components/FinalResultsTable.vue';
import FinalResultsCriteriasAndOptions from './components/FinalResultsCriteriasAndOptions.vue';
import Levels from './components/Briefing/Levels.vue';
import Categories from './components/Briefing/Categories.vue';
import CompetitionHeader from './components/CompetitionHeader.vue';

export default {
  components: { FinalResultsTable, FinalResultsCriteriasAndOptions, Levels, Categories, CompetitionHeader },
  data(){
    return {
        LEVELS: [],
        excludeOpens: false,
    };
  },
  methods:{
    async refresh(){
        await this.showRefresher();

        var levels = this.currentCompetition._LEVELS
                        .where("competition_id", this.currentCompetition.id)
                        .orderBy(l => l.LEVEL.displayOrder)
                        .get()
                        .map(lvl => {
                            //console.log('lvl', lvl)
                            var cats = lvl._CATEGORIES.orderBy(c => c.CATEGORY.displayOrder).get().map(cat => {
                                var comps = cat._COMPETITORS.orderBy("rank").get().map((rst,i) => {
                                    rst.rowIndex = i + 1;
                                    return rst;
                                });
                                
                                cat.R_COMPETITORS = comps;
                                cat.code = cat.category;
                                cat.name = cat.CATEGORY.name;
                                return cat;
                            })
                            
                            lvl.R_CATEGORIES = cats;
                            return lvl;
                        });
        this.LEVELS = levels;
        this.hideRefresher();
    }
  },
  async mounted(){
    this.hideLoader();
    await this.refresh();
  }
}
</script>
